import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import get from "lodash.get"
import React from "react"
import {
  FaCalendarAlt,
  FaCommentDots,
  FaUserAlt,
  FaChevronRight,
} from "react-icons/fa"
import Layout from "../components/Layout"
import Link from "../components/Link"
import HeroV1 from "../components/slices/hero-v1"
import cn from "classnames"
import Slider from "../components/Slider"
import NewGamesJoined from "../components/NewGamesJoined"
import EventsVerticalSlider from "../components/EventsVerticalSlider"
export default function BlogTemplate({ data, pageContext }) {
  const slices = get(data, "prismicBlog.data.body")
  const show_widget_1 = get(data, "prismicBlog.data.show_widget_1")
  const show_widget_3 = get(data, "prismicBlog.data.show_widget_3")
  const widget_1_content = get(data, "prismicBlog.data.widget_1_content")
  const widget_1_title = get(data, "prismicBlog.data.widget_1_title.text")
  const widget_3_content = get(data, "prismicBlog.data.widget_3_content")
  const widget_3_title = get(data, "prismicBlog.data.widget_3_title.text")

  const show_newsletter_widget = get(
    data,
    "prismicBlog.data.show_newsletter_widget"
  )
  const show_new_games_joined = get(
    data,
    "prismicBlog.data.show_new_games_joined"
  )
  const newsletter_widget_title = get(
    data,
    "prismicBlog.data.newsletter_widget_title"
  )

  return (
    <Layout>
      {slices
        ? slices.map((slice, index) => {
            switch (slice.__typename) {
              case "PrismicBlogBodyHero":
                return <EventsVerticalSlider events={slice.items} />

              default:
                return <pre key={index}>{JSON.stringify(slice, null, 2)}</pre>
            }
          })
        : null}
      <section className="mt-0">
        <div className="container">
          <div className="blog-grid">
            <div>
              <div
                className="prose mb-12"
                dangerouslySetInnerHTML={{
                  __html: get(data, "prismicBlog.data.listing_title.html"),
                }}
              ></div>
              <div className="space-y-12">
                {data.posts.nodes.map((node, index) => {
                  return <ListItem key={index} post={node} />
                })}
              </div>
              <div className="flex items-center space-x-6 mt-16 text-sm">
                {pageContext.totalPages > 1 &&
                  Array.from({ length: pageContext.totalPages }).map(
                    (_, index) => {
                      return (
                        <div
                          className={cn({
                            "bg-primary rounded-full w-8 h-8  flex-center":
                              pageContext.currentPage == index + 1,
                          })}
                        >
                          <>
                            {pageContext.currentPage == index + 1 ? (
                              <span>{index + 1}</span>
                            ) : (
                              <Link to={`/events/${index == 0 ? "" : index + 1}`}>
                                {index + 1}
                              </Link>
                            )}
                          </>
                        </div>
                      )
                    }
                  )}
              </div>
            </div>
            <div className="space-y-8">
              {show_widget_1 && (
                <WhyEpik items={widget_1_content} title={widget_1_title} />
              )}
              {show_newsletter_widget && (
                <Newsletter title={newsletter_widget_title} />
              )}
              {show_widget_3 && (
                <WhyEpikList items={widget_3_content} title={widget_3_title} />
              )}
            </div>
          </div>
        </div>
      </section>
      {show_new_games_joined && <NewGamesJoined />}
    </Layout>
  )
}
export const query = graphql`
  query($skip: Int, $postsPerPage: Int) {
    prismicBlog(uid: { eq: "events" }) {
      uid
      data {
        listing_title {
          html
        }
        newsletter_widget_title
        show_new_games_joined
        show_newsletter_widget
        show_widget_1
        show_widget_3
        widget_1_content {
          text {
            html
          }
          icon {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        widget_1_title {
          text
        }
        widget_3_content {
          text {
            html
          }
        }
        widget_3_title {
          text
        }
        body {
          ... on PrismicBlogBodyHero {
            id
            primary {
              background_image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                html
                text
              }
            }
            items {
              event {
                document {
                  ... on PrismicPost {
                    data {
                      featured_image {
                        fluid(imgixParams: { q: 90 }) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      title {
                        html
                      }
                      excerpt {
                        html
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }

    posts: allPrismicPost(
      limit: $postsPerPage
      skip: $skip
      sort: { order: DESC, fields: data___date }
      filter: { tags: { in: ["event"] } }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
            raw
          }
          excerpt {
            html
          }
          featured_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }

          date(formatString: "MMMM, DD, YYYY")
          author
        }
      }
    }
  }
`

function ListItem({ post }) {
  const image = get(post, "data.featured_image.fluid")
  return (
    <>
      <div className=" ">
        <div className="row md:flex-no-wrap">
          <div className="col w-full md:w-64 flex-shrink-0">
            {image && (
              <GatsbyImage
                className="h-full"
                imgStyle={{ marginTop: 0 }}
                fluid={image}
              />
            )}
          </div>
          <div className="col  w-full md:w-auto">
            <div>
              <span className="bg-primary px-6 py-2 mb-2 inline-block text-sm leading-none">
                Event
              </span>
            </div>
            <Link to={`/event/${post.uid}`}>
              <h5 className="font-semibold mb-2 text-lg">
                {post.data.title.text}
              </h5>
              <div
                className="opacity-75 mb-4 text-sm leading-loose"
                dangerouslySetInnerHTML={{
                  __html: get(post, "data.excerpt.html"),
                }}
              ></div>
            </Link>
            <div className="flex flex-wrap items-center opacity-50 text-sm">
              {/*<div>
                <FaCommentDots className="inline-block mr-1" /> 6 comments
              </div>
              <div className="ml-10 mr-auto">*/}
              <div className="mr-auto">
                <FaUserAlt className="inline-block mr-1" /> By{" "}
                {get(post, "data.author")}
              </div>
              <div>
                <FaCalendarAlt className="inline-block mr-1" />{" "}
                {get(post, "data.date")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
    </>
  )
}

function WhyEpik({ title, items }) {
  return (
    <div className="bg-primary-dark p-8 pb-2 max-w-xl">
      <h2 className="font-semibold text-xl mb-0">{title}</h2>
      <div className="divide-y divide-white    ">
        {items &&
          items.map((item, i) => {
            return (
              <div
                key={i}
                className="grid gap-6 grid-flow-col auto-cols-auto justify-start mb-5 pt-6"
                style={{ "--divide-opacity": "0.1" }}
              >
                <div className="w-10 max-w-sm">
                  {get(item, "icon.fluid") && (
                    <GatsbyImage fluid={item.icon.fluid} />
                  )}
                </div>
                <div
                  className="prose-sm space-y-2  prose-no-h-margins p-opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: get(item, "text.html", ""),
                  }}
                ></div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

function Newsletter({ title }) {
  return (
    <div className="bg-primary-dark p-8  max-w-xl">
      <h2 className="leading-normal text-xl">{title}</h2>
      <form className="flex flex-col items-stretch space-y-4 mt-6" action="https://epikgg.typeform.com/to/jU1YoNNF" method="get">
        <input type="hidden" id="typeform-welcome" name="typeform-welcome" value="0"/>
        <input type="email" id="email" name="email" required placeholder="Your email" />
        <button type="submit">
          Sign Up Now <FaChevronRight className="ml-2" />
        </button>
      </form>
    </div>
  )
}

function WhyEpikList({ title, items }) {
  return (
    <div className="bg-primary-dark p-8 pb-2  max-w-xl">
      <h2 className="font-semibold text-xl mb-0">{title}</h2>
      <div className="divide-y divide-white    ">
        {items &&
          items.map((item, index) => {
            return (
              <div
                key={index}
                className="grid gap-6 grid-flow-col auto-cols-auto justify-start mb-5 pt-6"
                style={{ "--divide-opacity": "0.1" }}
              >
                <div className="border-2 text-primary text-2xl font-bold border-primary flex-center w-12 h-12 ">
                  {index + 1}
                </div>
                <div
                  className="prose-sm space-y-2  prose-no-h-margins p-opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: get(item, "text.html", ""),
                  }}
                ></div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
